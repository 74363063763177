<template>
  <div class="e-track-intro--wrap">
    <div id="bgSwiper" class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide video-slide">
          <video
            src="/images/Elektroschiene/Slides/smart_electric.mp4"
            playsinline
            autoplay
            muted
          />
        </div>
        <div
          class="swiper-slide"
          :style="{ background: `transparent url('/images/Elektroschiene/Slides/a.jpg') no-repeat right top`, 'background-size': 'cover'}"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-5 col-lg-3 overlay-col">
        <img src="/images/Elektroschiene/TypeChoice/electric.svg" class="img-responsive">
        <ul class="e-track-advantages">
          <li>{{ $t('etrack.advantages.third') }}</li>
          <li>{{ $t('etrack.advantages.first') }}</li>
          <li>{{ $t('etrack.advantages.second') }}</li>
        </ul>
      </div>
      <div class="col-md-7 col-lg-9 content-col d-flex flex-column">
        <div class="swiper-button-prev">
          <i class="material-icons">navigate_before</i>
        </div>
        <div class="swiper-button-next">
          <i class="material-icons">navigate_next</i>
        </div>
        <div class="swiper-pagination" />

        <div class="go-to-config d-flex flex-column">
          <e-track-btn
            :text="$t('etrack.startConfig')"
            @click.native="startNewConfig"
          />

          <config-loader
            :configs="etrackConfigs"
            :loading="isLoading"
            @config:selected="id => loadConfig(id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigLoader from '../components/ConfigLoader'
import ETrackBtn from '../components/ETrackBtn'

import Swiper from 'swiper'
import { Autoplay, Navigation, EffectFade } from 'swiper/modules'
import 'swiper/css/effect-fade'

export default {
  name: 'e-track-intro',

  components: {
    ConfigLoader,
    ETrackBtn,
  },

  data () {
    return {
      isLoading: false,
      slides: [
        '/images/Elektroschiene/Slides/a.jpg',
        '/images/Elektroschiene/Slides/b.jpg',
      ],
      swiper: null,
    }
  },

  computed: {
    configToLoad () {
      return this.$route.params.configToLoad
    },

    etrackConfigs () {
      return this.$store.state.configStorage.configs.filter(({ etrackState }) =>
        etrackState !== undefined
      )
    },
  },

  watch: {
    configToLoad () {
      this.configToLoad && this.loadConfig(this.configToLoad)
    }
  },

  mounted () {
    this.configToLoad && this.loadConfig(this.configToLoad)
    this.initSwiper()
  },

  methods: {
    /**
     * Uses the etrack-config with the given id to restore the article-
     * configuration (api-side) and the state of the client.
     *
     * @param {string} id
     * @returns {Promise}
     */
    async loadConfig (id) {
      const configToLoad = this.etrackConfigs.find(({ _id }) => _id === id)

      if (configToLoad) {
        this.isLoading = true
        const res = await this.$store.dispatch('etrack/restoreConfig', configToLoad)
        const success = res && res.ReturnCode === 'Ok'
        this.$notify({
          content: success ? this.$t('etrack.loadConfigSuccess') : this.$t('etrack.loadConfigError'),
          title: success ? this.$t('etrack.success') : this.$t('etrack.error'),
          type: success ? 'success' : 'error',
          placement: 'bottom-right',
          customClass: 'alert--above-content',
        })

        this.isLoading = false
        this.$router.replace({ name: 'ETrackTypeChoice' })
      }
    },

    startNewConfig () {
      this.$store.commit('etrack/reset')
      this.$router.replace({ name: 'ETrackTypeChoice' })
    },

    initSwiper () {
      const swiper = new Swiper(
        '#bgSwiper',
        {
          modules: [
            Autoplay,
            Navigation,
            EffectFade,
          ],
          loop: true,
          speed: 1000,
          effect: 'fade',
          fadeEffect: {
            crossFade: true
          },
          navigation: {
            nextEl: '.e-track-intro--wrap .swiper-button-next',
            prevEl: '.e-track-intro--wrap .swiper-button-prev',
          },
          autoplay: {
            delay: 7000,
            disableOnInteraction: false,
          },
        }
      )

      swiper.on('transitionStart', () => {
        const videos = document.querySelectorAll('.video-slide video')
        videos.forEach(video => {
          video.pause()
          video.currentTime = 0
          video.load()
        })
      })
    }
  }
}
</script>

<style lang="scss">
  .e-track-intro--wrap {
    --swiper-pagination-bullet-size: 1rem;
    --swiper-navigation-color: #fff;

    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    position: relative;

    .swiper-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;

      .swiper-slide {
        padding: 0;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .swiper-pagination-bullets {
      &.swiper-pagination-horizontal {
        bottom: 15px;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      &::before,
      &::after {
        display: none;
      }

      i.material-icons {
        font-size: 3.5rem;
      }
    }

    .swiper-button-prev {
      left: 30px;
    }

    .swiper-button-next {
      right: 30px;
    }

    .row {
      flex: 1;
      display: flex;
      margin: 0;
    }

    .overlay-col {
      width: 25vw;
      max-width: 520px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 0 2vw;

      img {
        width: 18vw;
        max-width: 300px;
        margin: 100px auto;
      }
    }

    .content-col {
      position: relative;
    }

    ul.e-track-advantages {
      list-style: none;
      padding: 0;

      li {
        color: $darkHeadlineBgColor;
        padding: 15px 0;
      }
    }

    .go-to-config {
      margin: auto auto 50px auto;

      .etrack-btn {
        min-width: 340px;
        margin: 0 0 12px 0;
      }
    }
  }
</style>
